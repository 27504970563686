/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useIntl} from 'react-intl'
import {getAcademicYears} from '../../../../app/modules/academicyear/academicyears-list/core/_requests'
import {AcademicYear,initialAcademicYear, initialAcademicYears} from '../../../../app/modules/academicyear/academicyears-list/core/_models'
import {useAuth} from '../../../../app/modules/auth'
import {useYear, setYear, setYearName} from './YearsManager'


const Years: FC = () => {
  const intl = useIntl()
  const {currentUser, auth, saveAuth} = useAuth()
  const [academicYears, setAcademicYears] = useState<AcademicYear[]>(initialAcademicYears)
  const [activeYear, setActiveYear] = useState<AcademicYear>(initialAcademicYear)
  const year = useYear()
 
  useEffect(() => {
    const fetchData = async () => {
      getAcademicYears('&subinstitutionId=' + currentUser?.subinstitutionId).then((values) => { 
        setAcademicYears(values.data!)
        // decide active year
        let selectedYear = values.data?.find(x => x.id?.toString() === year.toString())
        if(!selectedYear && values.data !== undefined)
          selectedYear = values.data[values.data?.length-1]
        
          console.log("if set year name ")
          console.log(selectedYear)

        if(selectedYear)
        {
          console.log("setYearName(String(selectedYear.name)) " + String(selectedYear.name))
          setYearName(String(selectedYear.name))
          setYear(String(selectedYear.id))
          setActiveYear(selectedYear)
        }
        else
        {
          console.log("else set year name ")
          setYearName('')
          setYear('')
          setActiveYear(initialAcademicYear)
        }
      }) 
    }

    fetchData().catch(console.error)  
  }, [])

  return (
    <div
      className='menu-item px-2'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-2'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: '7ir'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {activeYear.name}{' '}
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-155px py-4'>
        {academicYears.map((l) => (
          <div
            className='menu-item px-1'
            key={l.name}
            onClick={() => {
              setYearName(String(l.name))
              setYear(String(l.id))
              window.location.reload()
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-2', {active: l.id === activeYear.id})}
            >
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Years}
