import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {MessageType, MessageTypesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_MESSAGETYPE_URL = `${API_URL}/messagetype/getmessagetype`
const CREATE_MESSAGETYPE_URL = `${API_URL}/messagetype/addmessagetype`
const UPDATE_MESSAGETYPE_URL = `${API_URL}/messagetype/updatemessagetype`
const DELETE_MESSAGETYPE_URL = `${API_URL}/messagetype/deletemessagetype`
const GET_MESSAGETYPES_URL = `${API_URL}/messagetype/getmessagetypes`

const getMessageTypes = (query: string): Promise<MessageTypesQueryResponse> => {
  return axios
    .get(`${GET_MESSAGETYPES_URL}?${query}`)
    .then((d: AxiosResponse<MessageTypesQueryResponse>) => d.data)
}

const getMessageTypeById = (id: ID): Promise<MessageType | undefined> => {
  return axios
    .get(`${GET_MESSAGETYPE_URL}/${id}`)
    .then((response: AxiosResponse<Response<MessageType>>) => response.data)
    .then((response: Response<MessageType>) => response.data)
}

const createMessageType = (messagetype: MessageType): Promise<MessageType | undefined> => {
  return axios
    .post(CREATE_MESSAGETYPE_URL, messagetype)
    .then((response: AxiosResponse<Response<MessageType>>) => response.data)
    .then((response: Response<MessageType>) => response.data)
}

const updateMessageType = (messagetype: MessageType): Promise<MessageType | undefined> => {
  return axios
    .put(`${UPDATE_MESSAGETYPE_URL}/${messagetype.id}`, messagetype)
    .then((response: AxiosResponse<Response<MessageType>>) => response.data)
    .then((response: Response<MessageType>) => response.data)
}

const deleteMessageType = (messagetypeId: ID): Promise<void> => {
  return axios.delete(`${DELETE_MESSAGETYPE_URL}/${messagetypeId}`).then(() => {})
}

const deleteSelectedMessageTypes = (messagetypeIds: Array<ID>): Promise<void> => {
  const requests = messagetypeIds.map((id) => axios.delete(`${DELETE_MESSAGETYPE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getMessageTypes, deleteMessageType, deleteSelectedMessageTypes, getMessageTypeById, createMessageType, updateMessageType}
