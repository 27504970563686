import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Term, TermsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_TERM_URL = `${API_URL}/term/getterm`
const CREATE_TERM_URL = `${API_URL}/term/addterm`
const UPDATE_TERM_URL = `${API_URL}/term/updateterm`
const DELETE_TERM_URL = `${API_URL}/term/deleteterm`
const GET_TERMS_URL = `${API_URL}/term/getterms`

const getTerms = (query: string): Promise<TermsQueryResponse> => {
  return axios
    .get(`${GET_TERMS_URL}?${query}`)
    .then((d: AxiosResponse<TermsQueryResponse>) => d.data)
}

const getTermById = (id: ID): Promise<Term | undefined> => {
  return axios
    .get(`${GET_TERM_URL}/${id}`)
    .then((response: AxiosResponse<Response<Term>>) => response.data)
    .then((response: Response<Term>) => response.data)
}

const createTerm = (term: Term): Promise<Term | undefined> => {
  return axios
    .post(CREATE_TERM_URL, term)
    .then((response: AxiosResponse<Response<Term>>) => response.data)
    .then((response: Response<Term>) => response.data)
}

const updateTerm = (term: Term): Promise<Term | undefined> => {
  return axios
    .put(`${UPDATE_TERM_URL}/${term.id}`, term)
    .then((response: AxiosResponse<Response<Term>>) => response.data)
    .then((response: Response<Term>) => response.data)
}

const deleteTerm = (termId: ID): Promise<void> => {
  return axios.delete(`${DELETE_TERM_URL}/${termId}`).then(() => {})
}

const deleteSelectedTerms = (termIds: Array<ID>): Promise<void> => {
  const requests = termIds.map((id) => axios.delete(`${DELETE_TERM_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getTerms, deleteTerm, deleteSelectedTerms, getTermById, createTerm, updateTerm}
