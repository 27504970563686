import {useListView, ListViewProvider} from '../../../../../app/modules/announcement/announcements-list/core/ListViewProvider'
import {AnnouncementViewModal} from '../../../../../app/modules/announcement/announcements-list/announcement-view-modal/AnnouncementViewModal'
import {AnnouncementTableWidget} from './AnnouncementTableWidget'

const AnnouncementTableWidgetInner = () => {
  const {itemIdForSecondOperation} = useListView()

  return (
    <>
    <AnnouncementTableWidget/>
    {itemIdForSecondOperation !== undefined && <AnnouncementViewModal />}          
    </>
  )
}


const AnnouncementTableWidgetWrapper = () => (
    <ListViewProvider>
      <AnnouncementTableWidgetInner />
    </ListViewProvider>
)

export {AnnouncementTableWidgetWrapper}
