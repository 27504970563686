import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Language, LanguagesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_LANGUAGE_URL = `${API_URL}/language/getlanguage`
const CREATE_LANGUAGE_URL = `${API_URL}/language/addlanguage`
const UPDATE_LANGUAGE_URL = `${API_URL}/language/updatelanguage`
const DELETE_LANGUAGE_URL = `${API_URL}/language/deletelanguage`
const GET_LANGUAGES_URL = `${API_URL}/language/getlanguages`

const getLanguages = (query: string): Promise<LanguagesQueryResponse> => {
  return axios
    .get(`${GET_LANGUAGES_URL}?${query}`)
    .then((d: AxiosResponse<LanguagesQueryResponse>) => d.data)
}

const getLanguageById = (id: ID): Promise<Language | undefined> => {
  return axios
    .get(`${GET_LANGUAGE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Language>>) => response.data)
    .then((response: Response<Language>) => response.data)
}

const createLanguage = (language: Language): Promise<Language | undefined> => {
  return axios
    .post(CREATE_LANGUAGE_URL, language)
    .then((response: AxiosResponse<Response<Language>>) => response.data)
    .then((response: Response<Language>) => response.data)
}

const updateLanguage = (language: Language): Promise<Language | undefined> => {
  return axios
    .put(`${UPDATE_LANGUAGE_URL}/${language.id}`, language)
    .then((response: AxiosResponse<Response<Language>>) => response.data)
    .then((response: Response<Language>) => response.data)
}

const deleteLanguage = (languageId: ID): Promise<void> => {
  return axios.delete(`${DELETE_LANGUAGE_URL}/${languageId}`).then(() => {})
}

const deleteSelectedLanguages = (languageIds: Array<ID>): Promise<void> => {
  const requests = languageIds.map((id) => axios.delete(`${DELETE_LANGUAGE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getLanguages, deleteLanguage, deleteSelectedLanguages, getLanguageById, createLanguage, updateLanguage}
