import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {CustomerPaymentDone, CustomerPaymentDonesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_CUSTOMERPAYMENTDONE_URL = `${API_URL}/customerpaymentdone/getcustomerpaymentdone`
const GET_CUSTOMERPAYMENTDONE_COUNT_CHART_URL = `${API_URL}/customerpaymentdone/getcustomerpaymentdonecountchart`
const GET_CUSTOMERPAYMENTDONE_LESSON_COUNT_CHART_URL = `${API_URL}/customerpaymentdone/getcustomerpaymentdonelessoncountchart`
const CREATE_CUSTOMERPAYMENTDONE_URL = `${API_URL}/customerpaymentdone/addcustomerpaymentdone`
const UPDATE_CUSTOMERPAYMENTDONE_URL = `${API_URL}/customerpaymentdone/updatecustomerpaymentdone`
const DELETE_CUSTOMERPAYMENTDONE_URL = `${API_URL}/customerpaymentdone/deletecustomerpaymentdone`
const GET_CUSTOMERPAYMENTDONES_URL = `${API_URL}/customerpaymentdone/getcustomerpaymentdones`
const GET_CUSTOMER_CUSTOMERPAYMENTDONES_URL = `${API_URL}/customerpaymentdone/getcustomercustomerpaymentdones`

const getCustomerPaymentDones = (query: string): Promise<CustomerPaymentDonesQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMERPAYMENTDONES_URL}?${query}`)
    .then((d: AxiosResponse<CustomerPaymentDonesQueryResponse>) => d.data)
}

const getCustomerCustomerPaymentDones = (query: string): Promise<CustomerPaymentDonesQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_CUSTOMERPAYMENTDONES_URL}?${query}`)
    .then((d: AxiosResponse<CustomerPaymentDonesQueryResponse>) => d.data)
}

const getCustomerPaymentDoneById = (id: ID): Promise<CustomerPaymentDone | undefined> => {
  return axios
    .get(`${GET_CUSTOMERPAYMENTDONE_URL}/${id}`)
    .then((response: AxiosResponse<Response<CustomerPaymentDone>>) => response.data)
    .then((response: Response<CustomerPaymentDone>) => response.data)
}

const getCustomerPaymentDoneCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CUSTOMERPAYMENTDONE_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getCustomerPaymentDoneLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CUSTOMERPAYMENTDONE_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createCustomerPaymentDone = (customerpaymentdone: CustomerPaymentDone): Promise<CustomerPaymentDone | undefined> => {
  return axios
    .post(CREATE_CUSTOMERPAYMENTDONE_URL, customerpaymentdone)
    .then((response: AxiosResponse<Response<CustomerPaymentDone>>) => response.data)
    .then((response: Response<CustomerPaymentDone>) => response.data)
}

const updateCustomerPaymentDone = (customerpaymentdone: CustomerPaymentDone): Promise<CustomerPaymentDone | undefined> => {
  return axios
    .put(`${UPDATE_CUSTOMERPAYMENTDONE_URL}/${customerpaymentdone.id}`, customerpaymentdone)
    .then((response: AxiosResponse<Response<CustomerPaymentDone>>) => response.data)
    .then((response: Response<CustomerPaymentDone>) => response.data)
}

const deleteCustomerPaymentDone = (customerpaymentdoneId: ID): Promise<void> => {
  return axios.delete(`${DELETE_CUSTOMERPAYMENTDONE_URL}/${customerpaymentdoneId}`).then(() => {})
}

const deleteSelectedCustomerPaymentDones = (customerpaymentdoneIds: Array<ID>): Promise<void> => {
  const requests = customerpaymentdoneIds.map((id) => axios.delete(`${DELETE_CUSTOMERPAYMENTDONE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCustomerPaymentDones, getCustomerCustomerPaymentDones, deleteCustomerPaymentDone, getCustomerPaymentDoneCountChart, getCustomerPaymentDoneLessonCountChart, deleteSelectedCustomerPaymentDones, getCustomerPaymentDoneById, createCustomerPaymentDone, updateCustomerPaymentDone}
