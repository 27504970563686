/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {Roles} from './Roles'
import {Years} from './Years'
import {Terms} from './Terms'
import {useIntl} from 'react-intl'
import {RoleModel} from '../../../../app/modules/auth/core/_models'

const HeaderUserMenu: FC = () => {
  const {currentUser, auth, logout} = useAuth()
  const intl = useIntl()
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-225px'
      data-kt-menu='true'
    >
      <div className='menu-item px-1'>
        <div className='menu-content d-flex align-items-center px-1'>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_Name} {currentUser?.last_Name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <Roles />
      
      {!(auth?.currentRole===RoleModel.Admin ||
         auth?.currentRole===RoleModel.Seller)  ?
      ( <>
        <Years />

        <Terms />
        </>
      ) : <></>}

      <div className='menu-item px-2 my-1'>
        <Link to='/crafted/accounts/profile/overview' className='menu-link px-2'>
        {intl.formatMessage({id: 'mg6'})}
        </Link>
      </div>

      <div className='menu-item px-2'>
        <a onClick={logout} className='menu-link px-2'>
        {intl.formatMessage({id: '287'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
