/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  ANNOUNCEMENTS_QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {getAnnouncements} from './_requests'
import {Announcement} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {useAuth} from '../../../auth'
import {ClaimModel} from '../../../auth/core/_models'
import {useYear} from '../../../../../_metronic/partials/layout/header-menus/YearsManager'
import {useTerm} from '../../../../../_metronic/partials/layout/header-menus/TermsManager'

const QueryResponseContext = createResponseContext<Announcement>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {currentUser, auth} = useAuth()
  const currentYearId = useYear()
  const currentTermId = useTerm()
  
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${ANNOUNCEMENTS_QUERIES.ANNOUNCEMENTS_LIST}-${query}`,
    () => {
      if(currentUser?.claims?.includes(ClaimModel.announcement))
        setQuery(updatedQuery + "&subinstitutionId=" + currentUser?.subinstitutionId + '&academicYearId=' + currentYearId + '&termId=' + currentTermId) 
      else 
        setQuery(updatedQuery + "&subinstitutionId=" + currentUser?.subinstitutionId + '&academicYearId=' + currentYearId + '&termId=' + currentTermId + '&isCustomer=' + (currentUser?.customerId !== null) + '&isEmployee=' + (currentUser?.employeeId !== null) + '&isPerson=' + (currentUser?.personId !== null))
      return getAnnouncements(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
