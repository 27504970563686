import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Club, ClubsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_CLUB_URL = `${API_URL}/club/getclub`
const GET_CLUB_COUNT_CHART_URL = `${API_URL}/club/getclubcountchart`
const GET_CLUB_LESSON_COUNT_CHART_URL = `${API_URL}/club/getclublessoncountchart`
const CREATE_CLUB_URL = `${API_URL}/club/addclub`
const UPDATE_CLUB_URL = `${API_URL}/club/updateclub`
const DELETE_CLUB_URL = `${API_URL}/club/deleteclub`
const GET_CLUBS_URL = `${API_URL}/club/getclubs`
const GET_CUSTOMER_CLUBS_URL = `${API_URL}/club/getcustomerclubs`

const getClubs = (query: string): Promise<ClubsQueryResponse> => {
  return axios
    .get(`${GET_CLUBS_URL}?${query}`)
    .then((d: AxiosResponse<ClubsQueryResponse>) => d.data)
}

const getCustomerClubs = (query: string): Promise<ClubsQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_CLUBS_URL}?${query}`)
    .then((d: AxiosResponse<ClubsQueryResponse>) => d.data)
}

const getClubById = (id: ID): Promise<Club | undefined> => {
  return axios
    .get(`${GET_CLUB_URL}/${id}`)
    .then((response: AxiosResponse<Response<Club>>) => response.data)
    .then((response: Response<Club>) => response.data)
}

const getClubCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CLUB_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getClubLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CLUB_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createClub = (club: Club): Promise<Club | undefined> => {
  return axios
    .post(CREATE_CLUB_URL, club)
    .then((response: AxiosResponse<Response<Club>>) => response.data)
    .then((response: Response<Club>) => response.data)
}

const updateClub = (club: Club): Promise<Club | undefined> => {
  return axios
    .put(`${UPDATE_CLUB_URL}/${club.id}`, club)
    .then((response: AxiosResponse<Response<Club>>) => response.data)
    .then((response: Response<Club>) => response.data)
}

const deleteClub = (clubId: ID): Promise<void> => {
  return axios.delete(`${DELETE_CLUB_URL}/${clubId}`).then(() => {})
}

const deleteSelectedClubs = (clubIds: Array<ID>): Promise<void> => {
  const requests = clubIds.map((id) => axios.delete(`${DELETE_CLUB_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getClubs, getCustomerClubs, deleteClub, getClubCountChart, getClubLessonCountChart, deleteSelectedClubs, getClubById, createClub, updateClub}
