import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Message, MessagesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_MESSAGE_URL = `${API_URL}/message/getmessage`
const GET_MESSAGE_COUNT_CHART_URL = `${API_URL}/message/getmessagecountchart`
const GET_MESSAGE_LESSON_COUNT_CHART_URL = `${API_URL}/message/getmessagelessoncountchart`
const CREATE_MESSAGE_URL = `${API_URL}/message/addmessage`
const UPDATE_MESSAGE_URL = `${API_URL}/message/updatemessage`
const DELETE_MESSAGE_URL = `${API_URL}/message/deletemessage`
const GET_MESSAGES_URL = `${API_URL}/message/getmessages`
const GET_CUSTOMER_MESSAGES_URL = `${API_URL}/message/getcustomermessages`

const getMessages = (query: string): Promise<MessagesQueryResponse> => {
  return axios
    .get(`${GET_MESSAGES_URL}?${query}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => d.data)
}

const getCustomerMessages = (query: string): Promise<MessagesQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_MESSAGES_URL}?${query}`)
    .then((d: AxiosResponse<MessagesQueryResponse>) => d.data)
}

const getMessageById = (id: ID): Promise<Message | undefined> => {
  return axios
    .get(`${GET_MESSAGE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Message>>) => response.data)
    .then((response: Response<Message>) => response.data)
}

const getMessageCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_MESSAGE_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getMessageLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_MESSAGE_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}



const createMessage = (message: Message): Promise<Message | undefined> => {
  return axios
    .post(CREATE_MESSAGE_URL, message)
    .then((response: AxiosResponse<Response<Message>>) => response.data)
    .then((response: Response<Message>) => response.data)
}

const updateMessage = (message: Message): Promise<Message | undefined> => {
  return axios
    .put(`${UPDATE_MESSAGE_URL}/${message.id}`, message)
    .then((response: AxiosResponse<Response<Message>>) => response.data)
    .then((response: Response<Message>) => response.data)
}

const deleteMessage = (messageId: ID): Promise<void> => {
  return axios.delete(`${DELETE_MESSAGE_URL}/${messageId}`).then(() => {})
}

const deleteSelectedMessages = (messageIds: Array<ID>): Promise<void> => {
  const requests = messageIds.map((id) => axios.delete(`${DELETE_MESSAGE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getMessages, getCustomerMessages, deleteMessage, getMessageCountChart, getMessageLessonCountChart, deleteSelectedMessages, getMessageById, createMessage, updateMessage}
