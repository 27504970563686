import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const UserClaimPage = lazy(() => import('../modules/userclaim/UserClaimPage'))
  const UserLoginPage = lazy(() => import('../modules/userlogin/UserLoginPage'))
  const UserRolePage = lazy(() => import('../modules/userrole/UserRolePage'))
  const RoleClaimPage = lazy(() => import('../modules/roleclaim/RoleClaimPage'))
  const RolePage = lazy(() => import('../modules/role/RolePage'))

  const PersonTypePage = lazy(() => import('../modules/persontype/PersonTypePage'))

  const PersonPage = lazy(() => import('../modules/person/PersonPage'))

  const CityPage = lazy(() => import('../modules/city/CityPage'))

  const CountryPage = lazy(() => import('../modules/country/CountryPage'))

  const EmployeePage = lazy(() => import('../modules/employee/EmployeePage'))

  const EmployeeTypePage = lazy(() => import('../modules/employeetype/EmployeeTypePage'))

  const GenderPage = lazy(() => import('../modules/gender/GenderPage'))

  const InstitutionPage = lazy(() => import('../modules/institution/InstitutionPage'))

  const LanguagePage = lazy(() => import('../modules/language/LanguagePage'))

  const PlacePage = lazy(() => import('../modules/place/PlacePage'))

  const WorkingStatuePage = lazy(() => import('../modules/workingstatue/WorkingStatuePage'))

  const UnitTypePage = lazy(() => import('../modules/unittype/UnitTypePage'))

  const UnitPage = lazy(() => import('../modules/unit/UnitPage'))

  const ClassPage = lazy(() => import('../modules/class/ClassPage'))

  const TermPage = lazy(() => import('../modules/term/TermPage'))

  const SubinstitutionPage = lazy(() => import('../modules/subinstitution/SubinstitutionPage'))

  const StudyTypePage = lazy(() => import('../modules/studytype/StudyTypePage'))

  const StudyEventTypePage = lazy(() => import('../modules/studyeventtype/StudyEventTypePage'))

  const SubinstitutionTypePage = lazy(() => import('../modules/subinstitutiontype/SubinstitutionTypePage'))

  const LessonPage = lazy(() => import('../modules/lesson/LessonPage'))

  const StudyPage = lazy(() => import('../modules/study/StudyPage'))

  const CustomerPage = lazy(() => import('../modules/customer/CustomerPage'))

  const SubinstitutionSubinstitutionTypePage = lazy(() => import('../modules/subinstitutionsubinstitutiontype/SubinstitutionSubinstitutionTypePage'))

  const SubinstitutionTypeBranchPage = lazy(() => import('../modules/subinstitutiontypebranch/SubinstitutionTypeBranchPage'))

  const EmployeeLessonPage = lazy(() => import('../modules/employeelesson/EmployeeLessonPage'))

  const AssignmentAnswerPage = lazy(() => import('../modules/assignmentanswer/AssignmentAnswerPage'))

  const AssignmentGradePage = lazy(() => import('../modules/assignmentgrade/AssignmentGradePage'))

  const AssignmentPage = lazy(() => import('../modules/assignment/AssignmentPage'))

  const AnnouncementPage = lazy(() => import('../modules/announcement/AnnouncementPage'))

  const ClubPage = lazy(() => import('../modules/club/ClubPage'))

  const ClubCustomerPage = lazy(() => import('../modules/clubcustomer/ClubCustomerPage'))

  const ClubEmployeePage = lazy(() => import('../modules/clubemployee/ClubEmployeePage'))

  const ClubActivityPage = lazy(() => import('../modules/clubactivity/ClubActivityPage'))

  const GuidancePage = lazy(() => import('../modules/guidance/GuidancePage'))

  const ExamResultPage = lazy(() => import('../modules/examresult/ExamResultPage'))

  const ExamLessonFormPage = lazy(() => import('../modules/examlessonform/ExamLessonFormPage'))

  const ExamLessonResultPage = lazy(() => import('../modules/examlessonresult/ExamLessonResultPage'))

  const SubjectPage = lazy(() => import('../modules/subject/SubjectPage'))

  const ExamSubjectPage = lazy(() => import('../modules/examsubject/ExamSubjectPage'))

  const ExamQuestionResultPage = lazy(() => import('../modules/examquestionresult/ExamQuestionResultPage'))

  const SubinstitutionLessonPage = lazy(() => import('../modules/subinstitutionlesson/SubinstitutionLessonPage'))

  const AttendanceTypePage = lazy(() => import('../modules/attendancetype/AttendanceTypePage'))

  const SurveyTypePage = lazy(() => import('../modules/surveytype/SurveyTypePage'))

  const SurveyResultPage = lazy(() => import('../modules/surveyresult/SurveyResultPage'))

  const SurveyPage = lazy(() => import('../modules/survey/SurveyPage'))

  const SurveyQuestionPage = lazy(() => import('../modules/surveyquestion/SurveyQuestionPage'))

  const CurriculumPage = lazy(() => import('../modules/curriculum/CurriculumPage'))

  const AttendancePage = lazy(() => import('../modules/attendance/AttendancePage'))

  const ExamPage = lazy(() => import('../modules/exam/ExamPage'))

  const ExamTypePage = lazy(() => import('../modules/examtype/ExamTypePage'))

  const ExamFormPage = lazy(() => import('../modules/examform/ExamFormPage'))

  const ExamPartPage = lazy(() => import('../modules/exampart/ExamPartPage'))

  const ExamBookletTypePage = lazy(() => import('../modules/exambooklettype/ExamBookletTypePage'))


  const ExpensePage = lazy(() => import('../modules/expense/ExpensePage'))

  const CurriculumSubjectPage = lazy(() => import('../modules/curriculumsubject/CurriculumSubjectPage'))

  const FoodPage = lazy(() => import('../modules/food/FoodPage'))

  const FoodTypePage = lazy(() => import('../modules/foodtype/FoodTypePage'))

  const MealPage = lazy(() => import('../modules/meal/MealPage'))

  const HelpPage = lazy(() => import('../modules/help/HelpPage'))

  const MenuPage = lazy(() => import('../modules/menu/MenuPage'))

  const PaymentMethodPage = lazy(() => import('../modules/paymentmethod/PaymentMethodPage'))

  const SubinstitutionCateringPage = lazy(() => import('../modules/subinstitutioncatering/SubinstitutionCateringPage'))

  const PaymentStatuPage = lazy(() => import('../modules/paymentstatu/PaymentStatuPage'))

  const CateringPage = lazy(() => import('../modules/catering/CateringPage'))

  const AcademicYearPage = lazy(() => import('../modules/academicyear/AcademicYearPage'))

  const ShuttleCompanyPage = lazy(() => import('../modules/shuttlecompany/ShuttleCompanyPage'))

  const PaymentTypePage = lazy(() => import('../modules/paymenttype/PaymentTypePage'))

  const CustomerPaymentPage = lazy(() => import('../modules/customerpayment/CustomerPaymentPage'))

  const ShuttlePage = lazy(() => import('../modules/shuttle/ShuttlePage'))

  const ShuttleCustomerPage = lazy(() => import('../modules/shuttlecustomer/ShuttleCustomerPage'))

  const BloodGroupPage = lazy(() => import('../modules/bloodgroup/BloodGroupPage'))

  const HealthPage = lazy(() => import('../modules/health/HealthPage'))

  const MedicalInterventionPage = lazy(() => import('../modules/medicalintervention/MedicalInterventionPage'))

  const MedicalInterventionTypePage = lazy(() => import('../modules/medicalinterventiontype/MedicalInterventionTypePage'))

  const MedicalInterventionResultPage = lazy(() => import('../modules/medicalinterventionresult/MedicalInterventionResultPage'))

  const CustomerPaymentInstallmentPage = lazy(() => import('../modules/customerpaymentinstallment/CustomerPaymentInstallmentPage'))

  const BankPage = lazy(() => import('../modules/bank/BankPage'))

  const BankAccountPage = lazy(() => import('../modules/bankaccount/BankAccountPage'))

  const CustomerPaymentDonePage = lazy(() => import('../modules/customerpaymentdone/CustomerPaymentDonePage'))

  const SentryPage = lazy(() => import('../modules/sentry/SentryPage'))

  const SentryTypePage = lazy(() => import('../modules/sentrytype/SentryTypePage'))

  const ExpenseTypePage = lazy(() => import('../modules/expensetype/ExpenseTypePage'))



  const MessageTypePage = lazy(() => import('../modules/messagetype/MessageTypePage'))

  const TicketTypePage = lazy(() => import('../modules/tickettype/TicketTypePage'))

  const TicketStatuPage = lazy(() => import('../modules/ticketstatu/TicketStatuPage'))

  const TicketPage = lazy(() => import('../modules/ticket/TicketPage'))

  const MessagePage = lazy(() => import('../modules/message/MessagePage'))

  const BankBranchPage = lazy(() => import('../modules/bankbranch/BankBranchPage'))

  const FoodIngredientPage = lazy(() => import('../modules/foodingredient/FoodIngredientPage'))


  const CustomerMenuPage = lazy(() => import('../modules/menu/CustomerMenuPage'))
  const ReportMenuPage = lazy(() => import('../modules/menu/ReportMenuPage'))

  const CustomerAssignmentPage = lazy(() => import('../modules/assignment/CustomerAssignmentPage'))
  const ReportAssignmentPage = lazy(() => import('../modules/assignment/ReportAssignmentPage'))

  const CustomerShuttlePage = lazy(() => import('../modules/shuttle/CustomerShuttlePage'))
  const ReportShuttlePage = lazy(() => import('../modules/shuttle/ReportShuttlePage'))

  const CustomerAnnouncementPage = lazy(() => import('../modules/announcement/CustomerAnnouncementPage'))
  const ReportAnnouncementPage = lazy(() => import('../modules/announcement/ReportAnnouncementPage'))

  const CustomerClubPage = lazy(() => import('../modules/club/CustomerClubPage'))
  const ReportClubPage = lazy(() => import('../modules/club/ReportClubPage'))

  const CustomerMedicalInterventionPage = lazy(() => import('../modules/medicalintervention/CustomerMedicalInterventionPage'))
  const ReportMedicalInterventionPage = lazy(() => import('../modules/medicalintervention/ReportMedicalInterventionPage'))

  const CustomerSurveyPage = lazy(() => import('../modules/survey/CustomerSurveyPage'))
  const ReportSurveyPage = lazy(() => import('../modules/survey/ReportSurveyPage'))

  const CustomerCustomerPaymentInstallmentPage = lazy(() => import('../modules/customerpaymentinstallment/CustomerCustomerPaymentInstallmentPage'))
  const ReportCustomerPaymentInstallmentPage = lazy(() => import('../modules/customerpaymentinstallment/ReportCustomerPaymentInstallmentPage'))

  const CustomerGuidancePage = lazy(() => import('../modules/guidance/CustomerGuidancePage'))
  const ReportGuidancePage = lazy(() => import('../modules/guidance/ReportGuidancePage'))

  const CustomerCurriculumPage = lazy(() => import('../modules/curriculum/CustomerCurriculumPage'))
  const ReportCurriculumPage = lazy(() => import('../modules/curriculum/ReportCurriculumPage'))

  const CustomerMessagePage = lazy(() => import('../modules/message/CustomerMessagePage'))
  const ReportMessagePage = lazy(() => import('../modules/message/ReportMessagePage'))

  const CustomerAttendancePage = lazy(() => import('../modules/attendance/CustomerAttendancePage'))
  const ReportAttendancePage = lazy(() => import('../modules/attendance/ReportAttendancePage'))

  const CustomerExamPage = lazy(() => import('../modules/exam/CustomerExamPage'))
  const ReportExamPage = lazy(() => import('../modules/exam/ReportExamPage'))

  const CustomerCustomerPaymentDonePage = lazy(() => import('../modules/customerpaymentdone/CustomerCustomerPaymentDonePage'))
  const ReportCustomerPaymentDonePage = lazy(() => import('../modules/customerpaymentdone/ReportCustomerPaymentDonePage'))

  const CustomerStudyPage = lazy(() => import('../modules/study/CustomerStudyPage'))
  const ReportStudyPage = lazy(() => import('../modules/study/ReportStudyPage'))

  const DashboardStudyPage = lazy(() => import('../modules/study/DashboardStudyPage'))

  const DashboardAnnouncementPage = lazy(() => import('../modules/announcement/DashboardAnnouncementPage'))

  const DashboardAssignmentPage = lazy(() => import('../modules/assignment/DashboardAssignmentPage'))

  const DashboardEmployeePage = lazy(() => import('../modules/employee/DashboardEmployeePage'))

  const DashboardCustomerPage = lazy(() => import('../modules/customer/DashboardCustomerPage'))

  const CustomerInfoPage = lazy(() => import('../modules/customer/CustomerInfoPage'))

  const DashboardSurveyPage = lazy(() => import('../modules/survey/DashboardSurveyPage'))

  const DashboardClubPage = lazy(() => import('../modules/club/DashboardClubPage'))

  const DashboardGuidancePage = lazy(() => import('../modules/guidance/DashboardGuidancePage'))

  const DashboardCustomerPaymentDonePage = lazy(() => import('../modules/customerpaymentdone/DashboardCustomerPaymentDonePage'))

  const DashboardMenuPage = lazy(() => import('../modules/menu/DashboardMenuPage'))

  const DashboardShuttlePage = lazy(() => import('../modules/shuttle/DashboardShuttlePage'))

  const DashboardMedicalInterventionPage = lazy(() => import('../modules/medicalintervention/DashboardMedicalInterventionPage'))

  const DashboardExamPage = lazy(() => import('../modules/exam/DashboardExamPage'))

  const DashboardUserRolePage = lazy(() => import('../modules/userrole/DashboardUserRolePage'))

  const DashboardSubinstitutionPage = lazy(() => import('../modules/subinstitution/DashboardSubinstitutionPage'))

  const DashboardAttendancePage = lazy(() => import('../modules/attendance/DashboardAttendancePage'))

  const DashboardMessagePage = lazy(() => import('../modules/message/DashboardMessagePage'))

  const DashboardTicketPage = lazy(() => import('../modules/ticket/DashboardTicketPage'))

  const DashboardCurriculumPage = lazy(() => import('../modules/curriculum/DashboardCurriculumPage'))


  const CurrencyPage = lazy(() => import('../modules/currency/CurrencyPage'))

  const PricingPage = lazy(() => import('../modules/pricing/PricingPage'))

  const SalePage = lazy(() => import('../modules/sale/SalePage'))

  const SellerPage = lazy(() => import('../modules/seller/SellerPage'))

  const OfferStatusPage = lazy(() => import('../modules/offerstatus/OfferStatuPage'))

  const OfferTypePage = lazy(() => import('../modules/offertype/OfferTypePage'))

  const OfferPage = lazy(() => import('../modules/offer/OfferPage'))

  const FilePage = lazy(() => import('../modules/file/FilePage'))

  const FileGalleryPage = lazy(() => import('../modules/file/FileGalleryPage'))

  const FileSharePage = lazy(() => import('../modules/fileshare/FileSharePage'))

  const FileTypePage = lazy(() => import('../modules/filetype/FileTypePage'))

  const GalleryPage = lazy(() => import('../modules/gallery/GalleryPage'))

  const CourseSkillPage = lazy(() => import('../modules/courseskill/CourseSkillPage'))

  const CourseResultPage = lazy(() => import('../modules/courseresult/CourseResultPage'))

  const CourseFinishedPage = lazy(() => import('../modules/coursefinished/CourseFinishedPage'))

  const CourseResultTypePage = lazy(() => import('../modules/courseresulttype/CourseResultTypePage'))

  const CoursePage = lazy(() => import('../modules/course/CoursePage'))

  const CourseTypePage = lazy(() => import('../modules/coursetype/CourseTypePage'))

  const CourseLevelPage = lazy(() => import('../modules/courselevel/CourseLevelPage'))

  const ProficiencyTestPage = lazy(() => import('../modules/proficiencytest/ProficiencyTestPage'))

  const ModulePage = lazy(() => import('../modules/module/ModulePage'))

  const PagePage = lazy(() => import('../modules/page/PagePage'))

  const SettingPage = lazy(() => import('../modules/setting/SettingPage'))

  const ClaimValuePage = lazy(() => import('../modules/claimvalue/ClaimValuePage'))

  const ModuleInstitutionPage = lazy(() => import('../modules/moduleinstitution/ModuleInstitutionPage'))

  const LogsPage = lazy(() => import('../modules/logs/LogPage'))

  const CustomerAcademicYearPage = lazy(() => import('../modules/customeracademicyear/CustomerAcademicYearPage'))

  const PersonAcademicYearPage = lazy(() => import('../modules/personacademicyear/PersonAcademicYearPage'))

  const ShuttleAcademicYearPage = lazy(() => import('../modules/shuttleacademicyear/ShuttleAcademicYearPage'))

  const CourseH5PPage = lazy(() => import('../modules/course/CourseH5PPage'))
 const CourseSkillQuestionPage = lazy(() => import('../modules/courseskillquestion/CourseSkillQuestionPage'))



  const FinishCoursePage = lazy(() => import('../modules/course/FinishCoursePage'))
const TakeCoursePage = lazy(() => import('../modules/course/TakeCoursePage')) 
  const CourseSkillFeedbackPage = lazy(() => import('../modules/courseskillfeedback/CourseSkillFeedbackPage'))   
  
  const CourseResultCriteriaPage = lazy(() => import('../modules/courseresultcriteria/CourseResultCriterionPage'))   
  
  const CourseSchedulePage = lazy(() => import('../modules/courseschedule/CourseSchedulePage'))   
  
  const PublishingOptionPage = lazy(() => import('../modules/publishingoption/PublishingOptionPage'))   
  
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/accounts/profile/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
<Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/users/user/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/users/role/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/users/roleclaim/*'
          element={
            <SuspensedView>
              <RoleClaimPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/users/userclaim/*'
          element={
            <SuspensedView>
              <UserClaimPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/users/userlogin/*'
          element={
            <SuspensedView>
              <UserLoginPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/users/userrole/*'
          element={
            <SuspensedView>
              <UserRolePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/institutions/persontype/*'
          element={
            <SuspensedView>
              <PersonTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/customers/person/*'
          element={
            <SuspensedView>
              <PersonPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/countries/city/*'
          element={
            <SuspensedView>
              <CityPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/countries/country/*'
          element={
            <SuspensedView>
              <CountryPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/employees/employee/*'
          element={
            <SuspensedView>
              <EmployeePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/employees/employeetype/*'
          element={
            <SuspensedView>
              <EmployeeTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/institutions/gender/*'
          element={
            <SuspensedView>
              <GenderPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/institutions/institution/*'
          element={
            <SuspensedView>
              <InstitutionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/countries/language/*'
          element={
            <SuspensedView>
              <LanguagePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/place/*'
          element={
            <SuspensedView>
              <PlacePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/employees/workingstatue/*'
          element={
            <SuspensedView>
              <WorkingStatuePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/employees/unittype/*'
          element={
            <SuspensedView>
              <UnitTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/employees/unit/*'
          element={
            <SuspensedView>
              <UnitPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/class/*'
          element={
            <SuspensedView>
              <ClassPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/term/*'
          element={
            <SuspensedView>
              <TermPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/subinstitution/*'
          element={
            <SuspensedView>
              <SubinstitutionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/studies/studytype/*'
          element={
            <SuspensedView>
              <StudyTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/studies/studyeventtype/*'
          element={
            <SuspensedView>
              <StudyEventTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/subinstitutiontype/*'
          element={
            <SuspensedView>
              <SubinstitutionTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/lesson/*'
          element={
            <SuspensedView>
              <LessonPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/studies/study/*'
          element={
            <SuspensedView>
              <StudyPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/customers/customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/subinstitutionsubinstitutiontype/*'
          element={
            <SuspensedView>
              <SubinstitutionSubinstitutionTypePage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/subinstitutions/subinstitutiontypebranch/*'
          element={
            <SuspensedView>
              <SubinstitutionTypeBranchPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/employees/employeelesson/*'
          element={
            <SuspensedView>
              <EmployeeLessonPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/assignments/assignmentanswer/*'
          element={
            <SuspensedView>
              <AssignmentAnswerPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/assignments/assignmentgrade/*'
          element={
            <SuspensedView>
              <AssignmentGradePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/assignments/assignment/*'
          element={
            <SuspensedView>
              <AssignmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/announcements/announcement/*'
          element={
            <SuspensedView>
              <AnnouncementPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/clubs/club/*'
          element={
            <SuspensedView>
              <ClubPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/clubs/clubcustomer/*'
          element={
            <SuspensedView>
              <ClubCustomerPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/clubs/clubemployee/*'
          element={
            <SuspensedView>
              <ClubEmployeePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/clubs/clubactivity/*'
          element={
            <SuspensedView>
              <ClubActivityPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/guidances/guidance/*'
          element={
            <SuspensedView>
              <GuidancePage />
            </SuspensedView>
          }
        />



        <Route
          path='crafted/exams/examresult/*'
          element={
            <SuspensedView>
              <ExamResultPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/examlessonform/*'
          element={
            <SuspensedView>
              <ExamLessonFormPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/examlessonresult/*'
          element={
            <SuspensedView>
              <ExamLessonResultPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/subject/*'
          element={
            <SuspensedView>
              <SubjectPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/examsubject/*'
          element={
            <SuspensedView>
              <ExamSubjectPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/examquestionresult/*'
          element={
            <SuspensedView>
              <ExamQuestionResultPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/curricula/subinstitutionlesson/*'
          element={
            <SuspensedView>
              <SubinstitutionLessonPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/attendances/attendancetype/*'
          element={
            <SuspensedView>
              <AttendanceTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/surveys/surveytype/*'
          element={
            <SuspensedView>
              <SurveyTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/surveys/surveyresult/*'
          element={
            <SuspensedView>
              <SurveyResultPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/surveys/survey/*'
          element={
            <SuspensedView>
              <SurveyPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/surveys/surveyquestion/*'
          element={
            <SuspensedView>
              <SurveyQuestionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/curricula/curriculum/*'
          element={
            <SuspensedView>
              <CurriculumPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/attendances/attendance/*'
          element={
            <SuspensedView>
              <AttendancePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/exam/*'
          element={
            <SuspensedView>
              <ExamPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/examtype/*'
          element={
            <SuspensedView>
              <ExamTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/examform/*'
          element={
            <SuspensedView>
              <ExamFormPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/exampart/*'
          element={
            <SuspensedView>
              <ExamPartPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/exambooklettype/*'
          element={
            <SuspensedView>
              <ExamBookletTypePage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/expenses/expense/*'
          element={
            <SuspensedView>
              <ExpensePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/curricula/curriculumsubject/*'
          element={
            <SuspensedView>
              <CurriculumSubjectPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/foodtype/*'
          element={
            <SuspensedView>
              <FoodTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/food/*'
          element={
            <SuspensedView>
              <FoodPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/meal/*'
          element={
            <SuspensedView>
              <MealPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/helps/help/*'
          element={
            <SuspensedView>
              <HelpPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/menu/*'
          element={
            <SuspensedView>
              <MenuPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/paymentmethod/*'
          element={
            <SuspensedView>
              <PaymentMethodPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/subinstitutioncatering/*'
          element={
            <SuspensedView>
              <SubinstitutionCateringPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/paymentstatu/*'
          element={
            <SuspensedView>
              <PaymentStatuPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/catering/*'
          element={
            <SuspensedView>
              <CateringPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/academicyear/*'
          element={
            <SuspensedView>
              <AcademicYearPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/shuttles/shuttlecompany/*'
          element={
            <SuspensedView>
              <ShuttleCompanyPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/paymenttype/*'
          element={
            <SuspensedView>
              <PaymentTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/customerpayment/*'
          element={
            <SuspensedView>
              <CustomerPaymentPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/shuttles/shuttle/*'
          element={
            <SuspensedView>
              <ShuttlePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/shuttles/shuttlecustomer/*'
          element={
            <SuspensedView>
              <ShuttleCustomerPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/medicalinterventions/bloodgroup/*'
          element={
            <SuspensedView>
              <BloodGroupPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/medicalinterventions/health/*'
          element={
            <SuspensedView>
              <HealthPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/medicalinterventions/medicalintervention/*'
          element={
            <SuspensedView>
              <MedicalInterventionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/medicalinterventions/medicalinterventiontype/*'
          element={
            <SuspensedView>
              <MedicalInterventionTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/medicalinterventions/medicalinterventionresult/*'
          element={
            <SuspensedView>
              <MedicalInterventionResultPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/customerpaymentinstallment/*'
          element={
            <SuspensedView>
              <CustomerPaymentInstallmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/bank/*'
          element={
            <SuspensedView>
              <BankPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/bankaccount/*'
          element={
            <SuspensedView>
              <BankAccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/customerpaymentdone/*'
          element={
            <SuspensedView>
              <CustomerPaymentDonePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/curricula/sentry/*'
          element={
            <SuspensedView>
              <SentryPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/curricula/sentrytype/*'
          element={
            <SuspensedView>
              <SentryTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/expensetype/*'
          element={
            <SuspensedView>
              <ExpenseTypePage />
            </SuspensedView>
          }
        />



        <Route
          path='crafted/messages/messagetype/*'
          element={
            <SuspensedView>
              <MessageTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/tickets/tickettype/*'
          element={
            <SuspensedView>
              <TicketTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/tickets/ticketstatu/*'
          element={
            <SuspensedView>
              <TicketStatuPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/tickets/ticket/*'
          element={
            <SuspensedView>
              <TicketPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/messages/message/*'
          element={
            <SuspensedView>
              <MessagePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/bankbranch/*'
          element={
            <SuspensedView>
              <BankBranchPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/foodingredient/*'
          element={
            <SuspensedView>
              <FoodIngredientPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/customermenu/*'
          element={
            <SuspensedView>
              <CustomerMenuPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/menus/reportmenu/*'
          element={
            <SuspensedView>
              <ReportMenuPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/assignments/customerassignment/*'
          element={
            <SuspensedView>
              <CustomerAssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/assignments/reportassignment/*'
          element={
            <SuspensedView>
              <ReportAssignmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/shuttles/customershuttle/*'
          element={
            <SuspensedView>
              <CustomerShuttlePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/shuttles/reportshuttle/*'
          element={
            <SuspensedView>
              <ReportShuttlePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/announcements/customerannouncement/*'
          element={
            <SuspensedView>
              <CustomerAnnouncementPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/announcements/reportannouncement/*'
          element={
            <SuspensedView>
              <ReportAnnouncementPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/clubs/customerclub/*'
          element={
            <SuspensedView>
              <CustomerClubPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/clubs/reportclub/*'
          element={
            <SuspensedView>
              <ReportClubPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/medicalinterventions/customermedicalintervention/*'
          element={
            <SuspensedView>
              <CustomerMedicalInterventionPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/medicalinterventions/reportmedicalintervention/*'
          element={
            <SuspensedView>
              <ReportMedicalInterventionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/surveys/customersurvey/*'
          element={
            <SuspensedView>
              <CustomerSurveyPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/surveys/reportsurvey/*'
          element={
            <SuspensedView>
              <ReportSurveyPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/customercustomerpaymentinstallment/*'
          element={
            <SuspensedView>
              <CustomerCustomerPaymentInstallmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/expenses/reportcustomerpaymentinstallment/*'
          element={
            <SuspensedView>
              <ReportCustomerPaymentInstallmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/guidances/customerguidance/*'
          element={
            <SuspensedView>
              <CustomerGuidancePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/guidances/reportguidance/*'
          element={
            <SuspensedView>
              <ReportGuidancePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/curricula/customercurriculum/*'
          element={
            <SuspensedView>
              <CustomerCurriculumPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/curricula/reportcurriculum/*'
          element={
            <SuspensedView>
              <ReportCurriculumPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/messages/customermessage/*'
          element={
            <SuspensedView>
              <CustomerMessagePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/messages/reportmessage/*'
          element={
            <SuspensedView>
              <ReportMessagePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/attendances/customerattendance/*'
          element={
            <SuspensedView>
              <CustomerAttendancePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/attendances/reportattendance/*'
          element={
            <SuspensedView>
              <ReportAttendancePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/exams/customerexam/*'
          element={
            <SuspensedView>
              <CustomerExamPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/exams/reportexam/*'
          element={
            <SuspensedView>
              <ReportExamPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/customercustomerpaymentdone/*'
          element={
            <SuspensedView>
              <CustomerCustomerPaymentDonePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/expenses/reportcustomerpaymentdone/*'
          element={
            <SuspensedView>
              <ReportCustomerPaymentDonePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/studies/customerstudy/*'
          element={
            <SuspensedView>
              <CustomerStudyPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/studies/reportstudy/*'
          element={
            <SuspensedView>
              <ReportStudyPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/studies/dashboardstudy/*'
          element={
            <SuspensedView>
              <DashboardStudyPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/announcements/dashboardannouncement/*'
          element={
            <SuspensedView>
              <DashboardAnnouncementPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/assignments/dashboardassignment/*'
          element={
            <SuspensedView>
              <DashboardAssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/employees/dashboardemployee/*'
          element={
            <SuspensedView>
              <DashboardEmployeePage />
            </SuspensedView>
          }

        />

        <Route
          path='crafted/customers/dashboardcustomer/*'
          element={
            <SuspensedView>
              <DashboardCustomerPage />
            </SuspensedView>
          }

        />

        <Route
          path='crafted/customers/customerinfo/*'
          element={
            <SuspensedView>
              <CustomerInfoPage />
            </SuspensedView>
          }

        />


        <Route
          path='crafted/clubs/dashboardclub/*'
          element={
            <SuspensedView>
              <DashboardClubPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/surveys/dashboardsurvey/*'
          element={
            <SuspensedView>
              <DashboardSurveyPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/guidances/dashboardguidance/*'
          element={
            <SuspensedView>
              <DashboardGuidancePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/expenses/dashboardcustomerpaymentdone/*'
          element={
            <SuspensedView>
              <DashboardCustomerPaymentDonePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/menus/dashboardmenu/*'
          element={
            <SuspensedView>
              <DashboardMenuPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/shuttles/dashboardshuttle/*'
          element={
            <SuspensedView>
              <DashboardShuttlePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/medicalinterventions/dashboardmedicalintervention/*'
          element={
            <SuspensedView>
              <DashboardMedicalInterventionPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/exams/dashboardexam/*'
          element={
            <SuspensedView>
              <DashboardExamPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/users/dashboarduserrole/*'
          element={
            <SuspensedView>
              <DashboardUserRolePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/subinstitutions/dashboardsubinstitution/*'
          element={
            <SuspensedView>
              <DashboardSubinstitutionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/attendances/dashboardattendance/*'
          element={
            <SuspensedView>
              <DashboardAttendancePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/messages/dashboardmessage/*'
          element={
            <SuspensedView>
              <DashboardMessagePage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/tickets/dashboardticket/*'
          element={
            <SuspensedView>
              <DashboardTicketPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/curricula/dashboardcurriculum/*'
          element={
            <SuspensedView>
              <DashboardCurriculumPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/sales/currency/*'
          element={
            <SuspensedView>
              <CurrencyPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/sales/pricing/*'
          element={
            <SuspensedView>
              <PricingPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/sales/sale/*'
          element={
            <SuspensedView>
              <SalePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/sales/seller/*'
          element={
            <SuspensedView>
              <SellerPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/sales/offerstatus/*'
          element={
            <SuspensedView>
              <OfferStatusPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/sales/offertype/*'
          element={
            <SuspensedView>
              <OfferTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/sales/offer/*'
          element={
            <SuspensedView>
              <OfferPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/accounts/filetype/*'
          element={
            <SuspensedView>
              <FileTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/accounts/fileshare/*'
          element={
            <SuspensedView>
              <FileSharePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/galleries/gallery/*'
          element={
            <SuspensedView>
              <GalleryPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/accounts/file/*'
          element={
            <SuspensedView>
              <FilePage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/modules/module/*'
          element={
            <SuspensedView>
              <ModulePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/modules/page/*'
          element={
            <SuspensedView>
              <PagePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/settings/setting/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/modules/claimvalue/*'
          element={
            <SuspensedView>
              <ClaimValuePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/modules/moduleinstitution/*'
          element={
            <SuspensedView>
              <ModuleInstitutionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/modules/logs/*'
          element={
            <SuspensedView>
              <LogsPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/customers/customeracademicyear/*'
          element={
            <SuspensedView>
              <CustomerAcademicYearPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/customers/personacademicyear/*'
          element={
            <SuspensedView>
              <PersonAcademicYearPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/shuttles/shuttleacademicyear/*'
          element={
            <SuspensedView>
              <ShuttleAcademicYearPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/course/*'
          element={
            <SuspensedView>
              <CoursePage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/courses/course/*'
          element={
            <SuspensedView>
              <CoursePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/interactivecourse/*'
          element={
            <SuspensedView>
              <CourseH5PPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/takecourse/*'
          element={
            <SuspensedView>
              <TakeCoursePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/finishcourse/*'
          element={
            <SuspensedView>
              <FinishCoursePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/coursetype/*'
          element={
            <SuspensedView>
              <CourseTypePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/courselevel/*'
          element={
            <SuspensedView>
              <CourseLevelPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/proficiencytest/*'
          element={
            <SuspensedView>
              <ProficiencyTestPage />
            </SuspensedView>
          }
        />      <Route
          path='crafted/galleries/filegallery/*'
          element={
            <SuspensedView>
              <FileGalleryPage />
            </SuspensedView>
          }
        />


        <Route
          path='crafted/courses/courseskill/*'
          element={
            <SuspensedView>
              <CourseSkillPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/courseresult/*'
          element={
            <SuspensedView>
              <CourseResultPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/coursefinished/*'
          element={
            <SuspensedView>
              <CourseFinishedPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courseskillquestions/courseskillquestion/*'
          element={
            <SuspensedView>
              <CourseSkillQuestionPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/courses/courseresulttype/*'
          element={
            <SuspensedView>
              <CourseResultTypePage />
            </SuspensedView>

          }
        />

        
        <Route
          path='crafted/courses/courseskillfeedback/*'
          element={
            <SuspensedView>
              <CourseSkillFeedbackPage />
            </SuspensedView>
          }
        />
        
        <Route
          path='crafted/courses/courseresultcriteria/*'
          element={
            <SuspensedView>
              <CourseResultCriteriaPage />
            </SuspensedView>
          }
        />
        
        <Route
          path='crafted/courses/courseschedule/*'
          element={
            <SuspensedView>
              <CourseSchedulePage />
            </SuspensedView>
          }
        />
        
        <Route
          path='crafted/courses/publishingoption/*'
          element={
            <SuspensedView>
              <PublishingOptionPage />
            </SuspensedView>
          }
        />
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
