import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Subinstitution, SubinstitutionsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_SUBINSTITUTION_URL = `${API_URL}/subinstitution/getsubinstitution`
const GET_SUBINSTITUTION_COUNT_URL = `${API_URL}/subinstitution/getsubinstitutioncount`
const GET_SUBINSTITUTION_COUNT_CHART_URL = `${API_URL}/subinstitution/getsubinstitutioncountchart`
const GET_SUBINSTITUTION_LESSON_COUNT_CHART_URL = `${API_URL}/subinstitution/getsubinstitutionlessoncountchart`
const CREATE_SUBINSTITUTION_URL = `${API_URL}/subinstitution/addsubinstitution`
const UPDATE_SUBINSTITUTION_URL = `${API_URL}/subinstitution/updatesubinstitution`
const DELETE_SUBINSTITUTION_URL = `${API_URL}/subinstitution/deletesubinstitution`
const GET_SUBINSTITUTIONS_URL = `${API_URL}/subinstitution/getsubinstitutions`

const getSubinstitutions = (query: string): Promise<SubinstitutionsQueryResponse> => {
  return axios
    .get(`${GET_SUBINSTITUTIONS_URL}?${query}`)
    .then((d: AxiosResponse<SubinstitutionsQueryResponse>) => d.data)
}

const getSubinstitutionById = (id: ID): Promise<Subinstitution | undefined> => {
  return axios
    .get(`${GET_SUBINSTITUTION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Subinstitution>>) => response.data)
    .then((response: Response<Subinstitution>) => response.data)
}

const getSubinstitutionCount = (institutionId: ID): Promise<number | undefined> => {
  return axios
    .get(`${GET_SUBINSTITUTION_COUNT_URL}/${institutionId}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const getSubinstitutionCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_SUBINSTITUTION_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getSubinstitutionLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_SUBINSTITUTION_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createSubinstitution = (subinstitution: Subinstitution): Promise<Subinstitution | undefined> => {
    return axios
    .post(CREATE_SUBINSTITUTION_URL, subinstitution)
    .then((response: AxiosResponse<Response<Subinstitution>>) => response.data)
    .then((response: Response<Subinstitution>) => response.data)
}

const updateSubinstitution = (subinstitution: Subinstitution): Promise<Subinstitution | undefined> => {
  return axios
    .put(`${UPDATE_SUBINSTITUTION_URL}/${subinstitution.id}`, subinstitution)
    .then((response: AxiosResponse<Response<Subinstitution>>) => response.data)
    .then((response: Response<Subinstitution>) => response.data)
}

const deleteSubinstitution = (subinstitutionId: ID): Promise<void> => {
  return axios.delete(`${DELETE_SUBINSTITUTION_URL}/${subinstitutionId}`).then(() => {})
}

const deleteSelectedSubinstitutions = (subinstitutionIds: Array<ID>): Promise<void> => {
  const requests = subinstitutionIds.map((id) => axios.delete(`${DELETE_SUBINSTITUTION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export { getSubinstitutions, getSubinstitutionCount, getSubinstitutionCountChart, getSubinstitutionLessonCountChart, deleteSubinstitution, deleteSelectedSubinstitutions, getSubinstitutionById, createSubinstitution, updateSubinstitution}
