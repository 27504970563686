/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {ID, KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {getMessages} from '../../../../../app/modules/message/messages-list/core/_requests'
import {useAuth} from '../../../../../app/modules/auth'
import {Message, initialMessages} from '../../../../../app/modules/message/messages-list/core/_models'
import { useIntl } from "react-intl";
import {useListView} from '../../../../../app/modules/message/messages-list/core/ListViewProvider'
import {MessageEditModal} from '../../../../../app/modules/message/messages-list/message-edit-modal/MessageEditModal'

type Props = {
  className: string
}

const MessageTableWidget: React.FC<Props> = ({className}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const intl = useIntl();
  const {currentUser} = useAuth()
  const [messages, setMessages] = useState<Message[]>(initialMessages)
  
  useEffect(() => {
    getMessages('&subinstitutionId=' + currentUser?.subinstitutionId + '&interlocuterUserId=' + currentUser?.id).then((values) => { setMessages(values.data!) })     
  }, [])

  const openEditModal = (id:ID) => {
    setItemIdForUpdate(id)
  }

  return (
    <>
    {itemIdForUpdate !== undefined && <MessageEditModal />}
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: 'whs'})}</span>
              <span className='text-muted fw-semibold fs-7'>{intl.formatMessage({id: 'pw3'})}</span>
            </h3>
            
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-5'>
                {/* begin::Table head */}
                <thead>
                  <tr>
                    <th className='p-0 min-w-360px'></th>
                    <th className='p-0 min-w-40px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {
                  messages.map(message => 
                  (<tr key={message?.id}>
                    <td>
                      <a onClick={() => openEditModal(message?.id)}  className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {message.title}
                      </a>
                      <span className='text-muted fw-semibold d-block fs-7'>{message?.text !== undefined ? (message?.text?.length > 50 ? message.text?.substring(0, 50) + '...' : message.text) : ''}</span>
                    </td>
                    <td className='text-end'>
                      <a onClick={() => openEditModal(message?.id)} className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                      </a>
                    </td>
                  </tr>
                  ))
                  }
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        </div>
    </>
  )
}

export {MessageTableWidget}
