import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Announcement, AnnouncementImage, AnnouncementsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ANNOUNCEMENT_URL = `${API_URL}/announcement/getannouncement`
const GET_ANNOUNCEMENT_COUNT_CHART_URL = `${API_URL}/announcement/getannouncementcountchart`
const GET_ANNOUNCEMENT_LESSON_COUNT_CHART_URL = `${API_URL}/announcement/getannouncementlessoncountchart`
const CREATE_ANNOUNCEMENT_URL = `${API_URL}/announcement/addannouncement`
const UPDATE_ANNOUNCEMENT_URL = `${API_URL}/announcement/updateannouncement`
const DELETE_ANNOUNCEMENT_URL = `${API_URL}/announcement/deleteannouncement`
const GET_ANNOUNCEMENTS_URL = `${API_URL}/announcement/getannouncements`
const GET_CUSTOMER_ANNOUNCEMENTS_URL = `${API_URL}/announcement/getcustomerannouncements`

const getAnnouncements = (query: string): Promise<AnnouncementsQueryResponse> => {
  return axios
    .get(`${GET_ANNOUNCEMENTS_URL}?${query}`)
    .then((d: AxiosResponse<AnnouncementsQueryResponse>) => d.data)
}

const getCustomerAnnouncements = (query: string): Promise<AnnouncementsQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_ANNOUNCEMENTS_URL}?${query}`)
    .then((d: AxiosResponse<AnnouncementsQueryResponse>) => d.data)
}

const getAnnouncementById = (id: ID): Promise<Announcement | undefined> => {
  return axios
    .get(`${GET_ANNOUNCEMENT_URL}/${id}`)
    .then((response: AxiosResponse<Response<Announcement>>) => response.data)
    .then((response: Response<Announcement>) => response.data)
}

const getAnnouncementCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_ANNOUNCEMENT_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getAnnouncementLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_ANNOUNCEMENT_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createAnnouncement = (announcement: Announcement): Promise<Announcement | undefined> => {
  return axios
    .post(CREATE_ANNOUNCEMENT_URL, announcement)
    .then((response: AxiosResponse<Response<Announcement>>) => response.data)
    .then((response: Response<Announcement>) => response.data)
}

const updateAnnouncement = (announcement: Announcement): Promise<Announcement | undefined> => {
  return axios
    .put(`${UPDATE_ANNOUNCEMENT_URL}/${announcement.id}`, announcement)
    .then((response: AxiosResponse<Response<Announcement>>) => response.data)
    .then((response: Response<Announcement>) => response.data)
}

const deleteAnnouncement = (announcementId: ID): Promise<void> => {
  return axios.delete(`${DELETE_ANNOUNCEMENT_URL}/${announcementId}`).then(() => {})
}

const deleteSelectedAnnouncements = (announcementIds: Array<ID>): Promise<void> => {
  const requests = announcementIds.map((id) => axios.delete(`${DELETE_ANNOUNCEMENT_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getAnnouncements, getCustomerAnnouncements, getAnnouncementCountChart, getAnnouncementLessonCountChart, deleteAnnouncement, deleteSelectedAnnouncements, getAnnouncementById, createAnnouncement, updateAnnouncement}
