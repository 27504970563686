import React from 'react';
import { useTimer } from 'react-timer-hook';
import { useIntl } from "react-intl";
 
type Props = {
  expiryTimestamp: Date,
  onExpire: () => void
}

const Timer: React.FC<Props> = ({expiryTimestamp, onExpire}) => {
  const intl = useIntl();
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning
  } = useTimer({ expiryTimestamp, onExpire: () => onExpire });
 
 
  return (
    <div style={{textAlign: 'center'}}>
      <div style={{fontSize: '32px'}}>
        {intl.formatMessage({ id: 'o9z' })}:<span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
    </div>
  );
}

export {Timer}