import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {UserRole, UserRolesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_USERROLE_URL = `${API_URL}/userrole/getuserrole`
const GET_USERROLE_COUNT_CHART_URL = `${API_URL}/userrole/getuserrolecountchart`
const GET_USERROLE_LESSON_COUNT_CHART_URL = `${API_URL}/userrole/getuserrolelessoncountchart`
const CREATE_USERROLE_URL = `${API_URL}/userrole/adduserrole`
const UPDATE_USERROLE_URL = `${API_URL}/userrole/updateuserrole`
const DELETE_USERROLE_URL = `${API_URL}/userrole/deleteuserrole`
const GET_USERROLES_URL = `${API_URL}/userrole/getuserroles`

const getUserRoles = (query: string): Promise<UserRolesQueryResponse> => {
  return axios
    .get(`${GET_USERROLES_URL}?${query}`)
    .then((d: AxiosResponse<UserRolesQueryResponse>) => d.data)
}

const getUserRoleById = (userId: ID, roleId: ID): Promise<UserRole | undefined> => {
  return axios
    .get(`${GET_USERROLE_URL}/${userId}/${roleId}`)
    .then((response: AxiosResponse<Response<UserRole>>) => response.data)
    .then((response: Response<UserRole>) => response.data)
}

const getUserRoleCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_USERROLE_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getUserRoleLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_USERROLE_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}


const createUserRole = (userrole: UserRole): Promise<UserRole | undefined> => {
  return axios
    .post(CREATE_USERROLE_URL, userrole)
    .then((response: AxiosResponse<Response<UserRole>>) => response.data)
    .then((response: Response<UserRole>) => response.data)
}

const updateUserRole = (userrole: UserRole): Promise<UserRole | undefined> => {
  return axios
    .put(`${UPDATE_USERROLE_URL}/${userrole.userId}/${userrole.roleId}`, userrole)
    .then((response: AxiosResponse<Response<UserRole>>) => response.data)
    .then((response: Response<UserRole>) => response.data)
}

const deleteUserRole = (userId: ID, roleId: ID): Promise<void> => {
  return axios.delete(`${DELETE_USERROLE_URL}/${userId}/${roleId}`).then(() => {})
}

const deleteSelectedUserRoles = (userroleIds: Array<ID>): Promise<void> => {
  const requests = userroleIds.map((id) => axios.delete(`${DELETE_USERROLE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUserRoles, deleteUserRole, getUserRoleCountChart, getUserRoleLessonCountChart, deleteSelectedUserRoles, getUserRoleById, createUserRole, updateUserRole}
