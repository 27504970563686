import {ID, Response} from '../../../../../_metronic/helpers'
export type Message = {
    id?: ID
    
    institutionId?: number
    subinstitutionId?: number
    messageTypeId?: number
    userId?: number
    interlocuterUserId?: number
    text?: string
    messageDate?: Date
    institutionName?: string
    subinstitutionName?: string
    messageTypeName?: string
    userName?: string
    userNameInterlocuter?: string
    title?: string
    academicYearId?: number
    termId?: number
}

export type MessagesQueryResponse = Response<Array<Message>>

export const initialMessage: Message = {
    
    institutionId: 0,
    subinstitutionId: 0,
    messageTypeId: undefined,
    userId: 0,
    interlocuterUserId: undefined,
    text: '',
    messageDate: new Date(),
    institutionName: '',
    subinstitutionName: '',
    messageTypeName: '',
    userName: '',
    userNameInterlocuter: '',
    title: '',
    academicYearId: 0,
    termId: 0
}

export const initialMessages: Message[] = [{
    
    institutionId: 0,
    subinstitutionId: 0,
    messageTypeId: 0,
    userId: 0,
    interlocuterUserId: 0,
    text: '',
    messageDate: new Date(),
    institutionName: '',
    subinstitutionName: '',
    messageTypeName: '',
    userName: '',
    userNameInterlocuter: '',
    title: '',
    academicYearId: 0,
    termId: 0
}]
