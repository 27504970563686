import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {AssignmentAnswer, AssignmentAnswersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ASSIGNMENTANSWER_URL = `${API_URL}/assignmentanswer/getassignmentanswer`
const GET_ASSIGNMENTANSWER_COUNT_URL = `${API_URL}/assignmentanswer/getassignmentanswercount`
const CREATE_ASSIGNMENTANSWER_URL = `${API_URL}/assignmentanswer/addassignmentanswer`
const UPDATE_ASSIGNMENTANSWER_URL = `${API_URL}/assignmentanswer/updateassignmentanswer`
const DELETE_ASSIGNMENTANSWER_URL = `${API_URL}/assignmentanswer/deleteassignmentanswer`
const GET_ASSIGNMENTANSWERS_URL = `${API_URL}/assignmentanswer/getassignmentanswers`

const getAssignmentAnswers = (query: string): Promise<AssignmentAnswersQueryResponse> => {
  return axios
    .get(`${GET_ASSIGNMENTANSWERS_URL}?${query}`)
    .then((d: AxiosResponse<AssignmentAnswersQueryResponse>) => d.data)
}

const getAssignmentAnswerById = (id: ID): Promise<AssignmentAnswer | undefined> => {
  return axios
    .get(`${GET_ASSIGNMENTANSWER_URL}/${id}`)
    .then((response: AxiosResponse<Response<AssignmentAnswer>>) => response.data)
    .then((response: Response<AssignmentAnswer>) => response.data)
}

const getAssignmentAnswerCount = (query: string): Promise<number | undefined> => {
  return axios
    .get(`${GET_ASSIGNMENTANSWER_COUNT_URL}?${query}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const createAssignmentAnswer = (assignmentanswer: AssignmentAnswer): Promise<AssignmentAnswer | undefined> => {
  return axios
    .post(CREATE_ASSIGNMENTANSWER_URL, assignmentanswer)
    .then((response: AxiosResponse<Response<AssignmentAnswer>>) => response.data)
    .then((response: Response<AssignmentAnswer>) => response.data)
}

const updateAssignmentAnswer = (assignmentanswer: AssignmentAnswer): Promise<AssignmentAnswer | undefined> => {
  return axios
    .put(`${UPDATE_ASSIGNMENTANSWER_URL}/${assignmentanswer.id}`, assignmentanswer)
    .then((response: AxiosResponse<Response<AssignmentAnswer>>) => response.data)
    .then((response: Response<AssignmentAnswer>) => response.data)
}

const deleteAssignmentAnswer = (assignmentanswerId: ID): Promise<void> => {
  return axios.delete(`${DELETE_ASSIGNMENTANSWER_URL}/${assignmentanswerId}`).then(() => {})
}

const deleteSelectedAssignmentAnswers = (assignmentanswerIds: Array<ID>): Promise<void> => {
  const requests = assignmentanswerIds.map((id) => axios.delete(`${DELETE_ASSIGNMENTANSWER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getAssignmentAnswers, getAssignmentAnswerCount, deleteAssignmentAnswer, deleteSelectedAssignmentAnswers, getAssignmentAnswerById, createAssignmentAnswer, updateAssignmentAnswer}
