/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {KTSVG} from '../../../helpers'
import {ChatInner} from '../../chat/ChatInner'
import {getUsers} from '../../../../app/modules/apps/user-management/users-list/core/_requests'
import {User, initialUser, initialUsers} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import {Chat, initialChat} from '../../../../app/modules/chat/chats-list/core/_models'
import {getUserById} from '../../../../app/modules/apps/user-management/users-list/core/_requests'
import {getLastChatIn} from '../../../../app/modules/chat/chats-list/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import { useIntl } from "react-intl"
import {RoleModel} from '../../../../app/modules/auth/core/_models'
import {getUnreadChatCounts} from '../../../../app/modules/chat/chats-list/core/_requests'
import {initialUnreadChatCounts} from '../../../../app/modules/chat/chats-list/core/_models'

const DrawerMessenger: FC = () => {
  const [users, setUsers] = useState(initialUsers)
  const [unreadChatCounts, setUnreadChatCounts] = useState(initialUnreadChatCounts)
  const [interlocutor, setInterlocutor] = useState<User | null>(null)
  const [refresh, setRefresh] = useState<boolean>(false)
  const {currentUser, auth} = useAuth()
  const [search, setSearch] = useState<string | undefined>(undefined)
  const intl = useIntl();


  useEffect(() => {
    getUsers('&subinstitutionId=' + currentUser?.subinstitutionId).then((values) => {       
        if(search)
        {
          setUsers(values.data!.filter(function (x) { return Number(x.userName?.toLowerCase().indexOf(search.toLowerCase())) > -1 })) 
        }
        else 
        {
          setUsers(values.data!.filter(x => x.id !== currentUser?.id))
        }
      })
      getUnreadChatCounts(currentUser?.id).then((counts) => {
        if(counts)
          setUnreadChatCounts(counts)
      })
  }, [search])

  const searchOnChange = (text: string) => {
    if(text.length >= 3)
      setSearch(text)
    else 
      setSearch(undefined)
  }

  const setInterlocutorId = (user:User) => {
    setInterlocutor(user)
    setRefresh(true)
  }
  const interlocutorOnChange = (user: User) => {
    setInterlocutorId(user)
    setUnreadChatCounts(unreadChatCounts.filter(x => x.userId !== user.id))
  }
  
  return (
  <div
    id='kt_drawer_chat'
    className='bg-body'
    data-kt-drawer='true'
    data-kt-drawer-name='chat'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_drawer_chat_toggle'
    data-kt-drawer-close='#kt_drawer_chat_close'
  >
    <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
      <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
        <div className='card-title'>
          <div className='d-flex justify-content-center flex-column me-3'>
            <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
              {intl.formatMessage({ id: 'a38' })}
            </a>
          </div>
        </div>

        <div className='card-toolbar'>
          
          <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
          </div>
        </div>
      </div>
      <div className='card-body pt-5' id='kt_chat_contacts_body'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='200px'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              {
              users.map((user, i) => 
              <>
              {!((user.role === RoleModel.Customer || user.role === RoleModel.Person) && (auth?.currentRole === RoleModel.Customer || auth?.currentRole === RoleModel.Person)) ?
              <div key={user.id} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  
                  <div className='ms-5'>
                    <a href='#' onClick={() => {interlocutorOnChange(user)}} className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'>
                    {user.userName}
                    </a>
                    <div className='fw-bold text-gray-400'>{user.email}</div>
                  </div>
                  {unreadChatCounts.some(x => x.userId === user.id) ?
                    (<div className='symbol symbol-45px symbol-circle'>
                      <span className='symbol-label bg-light-warning text-warning fs-6 fw-bolder'>
                      { unreadChatCounts.find(x => x.userId === user.id )?.unreadCount}
                      </span>
                    </div>) : <></>
                  }
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='text-muted fs-7 mb-1'>{user.role === RoleModel.Customer ? intl.formatMessage({ id: 'vxt' }) : user.role === RoleModel.Person ? intl.formatMessage({ id: 'pb0' }) : user.role === RoleModel.Employee ? intl.formatMessage({ id: '4ny' }) : ''}</span>
                </div>
              </div>
              : <></>
              }
              </>
              )} 
            </div>
          </div>
      {interlocutor !== null ?
      <ChatInner interlocutorId={Number(interlocutor?.id)} interlocutorUserName={String(interlocutor?.userName)} isDrawer={true} />
      : <></>}
    </div>
  </div>

  )
}

export {DrawerMessenger}
