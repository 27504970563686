import {useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import {AnnouncementViewModalForm} from './AnnouncementViewModalForm'
import {isNotEmpty, ANNOUNCEMENTS_QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getAnnouncementById} from '../core/_requests'

import {useAuth} from '../../../auth'

const AnnouncementViewModalFormWrapper = () => {
  const {itemIdForSecondOperation, setItemIdForSecondOperation} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForSecondOperation)
  
  const [showCreateAnnouncementModal, setShowCreateAnnouncementModal] = useState<boolean>(false)
  const {currentUser} = useAuth()
  
  const {
    isLoading,
    data: announcement,
    error,
  } = useQuery(
    `${ANNOUNCEMENTS_QUERIES.ANNOUNCEMENTS_LIST}-announcement-${itemIdForSecondOperation}`,
    () => {
      return getAnnouncementById(itemIdForSecondOperation)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        if(setItemIdForSecondOperation)
          setItemIdForSecondOperation(undefined)
        console.error(err)
      },
    }
  )
    
  useEffect(() => {
    setShowCreateAnnouncementModal(true)
  }, [itemIdForSecondOperation])
   

  if (!itemIdForSecondOperation) {
    return <AnnouncementViewModalForm isAnnouncementLoading={isLoading} announcement={{id: undefined}} show={showCreateAnnouncementModal} handleClose={() => { setShowCreateAnnouncementModal(false); setItemIdForSecondOperation ? setItemIdForSecondOperation(undefined) : <></>;} }  />
  }

  if (!isLoading && !error && announcement) {
    return <AnnouncementViewModalForm isAnnouncementLoading={isLoading} announcement={announcement} show={showCreateAnnouncementModal} handleClose={() => { setShowCreateAnnouncementModal(false); setItemIdForSecondOperation ? setItemIdForSecondOperation(undefined) : <></>} }  />
  }

  return null
}

export {AnnouncementViewModalFormWrapper}
