import {ID, Response} from '../../../../../_metronic/helpers'
export type Language = {
    id?: ID
    
    name?: string
    code?: string
}

export type LanguagesQueryResponse = Response<Array<Language>>

export const initialLanguage: Language = {
    
    name: '',
    code: ''
}

export const initialLanguages: Language[] = [{
    
    name: '',
    code: ''
}]
