/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {getCities} from '../../city/cities-list/core/_requests'
import {City, initialCities} from '../../city/cities-list/core/_models'
import {getCountries} from '../../country/countries-list/core/_requests'
import {Country, initialCountries} from '../../country/countries-list/core/_models'
import {getLanguages} from '../../language/languages-list/core/_requests'
import {Language, initialLanguages} from '../../language/languages-list/core/_models'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import Select from 'react-select'
import {SingleValue} from 'react-select'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  changepassword: '',
  institutionName: '',
  phone: '',
  countryId: 0,
  cityId: 0,
  languageId: 0,
  subinstitutionTypeId: 0,
  acceptTerms: false,
}



export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [cities, setCities] = useState<City[]>(initialCities)
  const [countries, setCountries] = useState<Country[]>(initialCountries)
  const [languages, setLanguages] = useState<Language[]>(initialLanguages)
  const intl = useIntl()
  const lang = useLang()
  const [selectedCountry, setSelectedCountry] = useState<SingleValue<{value:number | undefined, label:string}>>({value: undefined, label: intl.formatMessage({ id: "r2m" })})
  const [selectedCity, setSelectedCity] = useState<SingleValue<{value:number | undefined, label:string}>>({value: undefined, label: intl.formatMessage({ id: "r2m" })})
  const [selectedLanguage, setSelectedLanguage] = useState<SingleValue<{value:number | undefined, label:string}>>({value: undefined, label: intl.formatMessage({ id: "r2m" })})
  const [selectedSubinstitutionType, setSelectedSubinstitutionType] = useState<SingleValue<{value:number | undefined, label:string}>>({value: undefined, label: intl.formatMessage({ id: "r2m" })})

  const registrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "xdr" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "0yr" }))
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "xdr" })),
    lastName: Yup.string()
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "xdr" })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "xdr" })),
    changepassword: Yup.string()
      .required(intl.formatMessage({ id: "po7" }) + intl.formatMessage({ id: "xdr" }))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], intl.formatMessage({ id: "k8q" })),
      }),
    institutionName: Yup.string()
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "xdr" })),
    phone: Yup.string()
      .min(3, intl.formatMessage({ id: "em7" }))
      .max(50, intl.formatMessage({ id: "ubm" }))
      .required(intl.formatMessage({ id: "xdr" })),
    cityId: Yup.string()
      .required(intl.formatMessage({ id: "xdr" })),
    languageId: Yup.string()
      .required(intl.formatMessage({ id: "xdr" })),
    subinstitutionTypeId: Yup.string()
      .required(intl.formatMessage({ id: "xdr" })),
    acceptTerms: Yup.bool().required(intl.formatMessage({ id: "qc8" }) + intl.formatMessage({ id: "xdr" })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await register(
          values.email,
          values.firstName,
          values.lastName,
          values.password,
          values.changepassword,
          values.institutionName,
          values.phone,
          values.cityId,
          values.languageId,
          values.subinstitutionTypeId
        ).then(async (auth) => {
          saveAuth(auth)
          if (auth && auth.api_token) 
            await getUserByToken(String(auth.api_token), auth?.currentRole).then((user) => {
              setCurrentUser(user)
            })
        })
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'bzr'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    getCountries('').then((values) => { 
      setCountries(values.data!) 
      if(lang === 'tr')
      {
        setSelectedCountry({value: 218, label: "TÜRKİYE"})
        getCities('&countryId=218').then((values) => { setCities(values.data!) })        
      }
    })
    getLanguages('').then((values) => { setLanguages(values.data!) })
  }, [])

  const setCountry = (item: SingleValue<{value:number | undefined, label:string}>) => {
    setSelectedCountry(item)
    setSelectedCity({value: undefined, label: intl.formatMessage({ id: "r2m" })})
    getCities('&countryId=' + item?.value).then((values) => { setCities(values.data!) })
  }

  const setCity = (item: SingleValue<{value:number | undefined, label:string}>) => {
    setSelectedCity(item)
    formik.setFieldValue("cityId", item?.value)
  }

  const setLanguage = (item: SingleValue<{value:number | undefined, label:string}>) => {
    setSelectedLanguage(item)
    formik.setFieldValue("languageId", item?.value)
  }

  const setSubinstitutionType = (item: SingleValue<{value:number | undefined, label:string}>) => {
    setSelectedSubinstitutionType(item)
    formik.setFieldValue("subinstitutionTypeId", item?.value)
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: '7fk'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
        {intl.formatMessage({id: 'ku2'})}
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
          {intl.formatMessage({id: 'aj2'})}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'rbe'})}</label>
          <input
            placeholder={intl.formatMessage({id: 'rbe'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstName && formik.errors.firstName,
              },
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              }
            )}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'isc'})}</label>
            <input
              placeholder={intl.formatMessage({id: 'isc'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastName && formik.errors.lastName,
                },
                {
                  'is-valid': formik.touched.lastName && !formik.errors.lastName,
                }
              )}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Institution */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'qov'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'qov'})}
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('institutionName')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.institutionName && formik.errors.institutionName},
            {
              'is-valid': formik.touched.institutionName && !formik.errors.institutionName,
            }
          )}
        />
        {formik.touched.institutionName && formik.errors.institutionName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.institutionName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-7'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: '6s9'})}</label>
            <input
              placeholder={intl.formatMessage({id: '6s9'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              </div>
            )}
          </div>
        </div>

      {/* begin::Form group Institution */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
        <label className='class="form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'l0p'})}</label>
            <Select
              value={selectedSubinstitutionType}
              onChange={setSubinstitutionType}
              options={[ 
                { value: 1, label: intl.formatMessage({id: 'dlo'}) },
                { value: 2, label: intl.formatMessage({id: 'lo9'}) },
                { value: 3, label: intl.formatMessage({id: 'kse'}) },
                { value: 4, label: intl.formatMessage({id: 'h1w'}) },
              ]}
            />
          {formik.touched.subinstitutionTypeId && formik.errors.subinstitutionTypeId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.subinstitutionTypeId}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: '2qg'})}</label>
          <Select
              value={selectedLanguage}
              onChange={setLanguage}
              options={
                languages.map((language) => {
                  return { value: Number(language.id), label: String(language.name) }
                })
              }
            />
          {formik.touched.languageId && formik.errors.languageId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.languageId}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}
      
      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'o9q'})}</label>
            <Select
              value={selectedCountry}
              onChange={setCountry}
              options={
                countries.map((country) => {
                  return { value: Number(country.id), label: String(country.name) }
                })
              }
            />
            {formik.touched.countryId && formik.errors.countryId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.countryId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 're9'})}</label>
            <Select
              value={selectedCity}
              onChange={setCity}
              options={
                cities.map((city) => {
                  return { value: Number(city.id), label: String(city.name) }
                })
              }
            />
            {formik.touched.cityId && formik.errors.cityId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cityId}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'p9z'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'r2m'})}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: '9xk'})}</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: '9xk'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          {intl.formatMessage({id: 'akk'})}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'rt5'})}</label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'rt5'})}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            {intl.formatMessage({id: 'ebh'})}{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
            {intl.formatMessage({id: 'uok'})}  
            </Link>
            {intl.formatMessage({id: 'zxv'})}
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'rac'})}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'v3x'})}...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({id: 'bmg'})}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
