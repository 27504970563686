/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, useRef} from 'react'
import clsx from 'clsx'
import { useIntl } from "react-intl";
import {User, initialUser} from '../../../app/modules/apps/user-management/users-list/core/_models'
import {Chat, Group, initialChats} from '../../../app/modules/chat/chats-list/core/_models'
import { toAbsoluteUrl } from '../../helpers'
import {getChats, createChat, updateChat, joinGroup} from '../../../app/modules/chat/chats-list/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import { getUserById } from '../../../app/modules/apps/user-management/users-list/core/_requests'
import { TIMEOUT } from 'dns';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { error} from 'toastr'
import { toUSVString } from 'util';

type Props = {
  interlocutorId?: number
  interlocutorUserName?: string
  isDrawer?: boolean
}

const bufferMessages = initialChats

const ChatInner: FC<Props> = ({interlocutorId, interlocutorUserName, isDrawer = false}) => {
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<Chat[]>(initialChats)
  const intl = useIntl();
  const {currentUser, auth} = useAuth()
  const [images, setImages] = useState(toAbsoluteUrl('/media/avatars/blank.png'));
  const latestChat = useRef<Chat[] | null>(null);
  const [ connection, setConnection ] = useState<HubConnection>();
  const [ group, setGroup ] = useState<Group>();

  latestChat.current = messages

    useEffect(() => {
      if(interlocutorId !== undefined)
      {
        getChats('&userId=' + currentUser?.id + '&interlocutorUserId=' + interlocutorId).then((values) => { 
            setMessages(values.data!) 
            updateScroll() 
            const connect = new HubConnectionBuilder()
                .withUrl('https://okulapi.oprazi.com/api/hubs/chat')
                .withAutomaticReconnect()
                .build();

                connect.start()
                .then(result => {
                    var groupName = '';
                    if(Number(currentUser?.id) < Number(interlocutorId))
                      groupName = currentUser?.id + '-' + interlocutorId
                    else 
                      groupName = interlocutorId + '-' + currentUser?.id
                    const newGroup : Group =  
                    { 
                      connectionId: String(connect.connectionId),
                      name: groupName
                    }
                    setGroup(newGroup)
                    joinGroup(newGroup).then(() => {
                      connect.on('ReceiveMessage', message => {
                        const updatedChat = [...latestChat.current ?? []];
                        updatedChat.push(message);
                        setMessages(updatedChat);
                        updateScroll()
        
                      });
                    })
                    
                })
                .catch(ex => 
                  error(ex as string,
                    ( intl.formatMessage({ id: "CHAT.CONNECTION.ERROR.TITLE" })),
                      {timeOut: 5000})
                  
                  );
                
            setConnection(connect)
            if(interlocutorId)
              values.data!.map((message) => {
                if(!message.read)
                {
                  message.read = true
                  message.updatedBy = currentUser?.id
                  message.updatedOn = new Date()
                  updateChat(message)
                }
              })
          })   
    }
  }, [interlocutorId]);

  const sendMessage = async () => {
    const newMessage: Chat = {
      userId: currentUser?.id,
      type: 'out',
      text: message,
      time: new Date(),
      interlocutorUserId: Number(interlocutorId),
      read: false
    }
    
    if(connection !== undefined)
    {
      await createChat(newMessage)
      setMessage('')
      updateScroll()
    }
  }

  function updateScroll(){
    var element = document.getElementById("oprazi_messages");
    if(element !== undefined && element !== null)
      element.scrollTop = element.scrollHeight;
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <>
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
        id='oprazi_messages'
        data-kt-element='oprazi_messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {messages.map((message, index) => {
          //const userInfo = interlocutor //userInfos[message.userId !== undefined ? message.userId : 0]
          const state = message.userId !== currentUser?.id ? 'info' : 'primary'
          //const templateAttr = {}
          /*if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }*/
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.userId !== currentUser?.id ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': false})}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.userId !== currentUser?.id ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.userId !== currentUser?.id ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/blank.png`)} />
                      </div>
                      <div className='ms-3'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                        >
                          {interlocutorUserName}
                        </a>
                        <span className='text-muted fs-7 mb-1'>{message.time?.toString()}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>{message.time?.toString()}</span>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                        >
                          { intl.formatMessage({ id: "lqs" })}
                        </a>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                      <img alt={currentUser?.username} src={images} />
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-5 rounded',
                    `bg-light-${state}`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-${message.userId !== currentUser?.id ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{__html: message.text !== undefined ? message.text : ''}}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
      
      <div
      className='card-footer pt-4'
      id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
      <textarea
        className='form-control form-control-flush mb-3'
        rows={1}
        data-kt-element='input'
        placeholder={ intl.formatMessage({ id: "qos" })}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={onEnterPress}
      ></textarea>

      <div className='d-flex flex-stack'>
        <div className='d-flex align-items-center me-2'>
          
        </div>
        <button
          className='btn btn-primary'
          type='button'
          data-kt-element='send'
          onClick={sendMessage}
        >
          {intl.formatMessage({ id: "e6o" })}
        </button>
      </div>
      </div>
    </div>

    
      
      </>
  )
}

export {ChatInner}
