import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Guidance, GuidancesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_GUIDANCE_URL = `${API_URL}/guidance/getguidance`
const GET_GUIDANCE_COUNT_CHART_URL = `${API_URL}/guidance/getguidancecountchart`
const GET_GUIDANCE_LESSON_COUNT_CHART_URL = `${API_URL}/guidance/getguidancelessoncountchart`
const CREATE_GUIDANCE_URL = `${API_URL}/guidance/addguidance`
const UPDATE_GUIDANCE_URL = `${API_URL}/guidance/updateguidance`
const DELETE_GUIDANCE_URL = `${API_URL}/guidance/deleteguidance`
const GET_GUIDANCES_URL = `${API_URL}/guidance/getguidances`
const GET_CUSTOMER_GUIDANCES_URL = `${API_URL}/guidance/getcustomerguidances`

const getGuidances = (query: string): Promise<GuidancesQueryResponse> => {
  return axios
    .get(`${GET_GUIDANCES_URL}?${query}`)
    .then((d: AxiosResponse<GuidancesQueryResponse>) => d.data)
}

const getCustomerGuidances = (query: string): Promise<GuidancesQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMER_GUIDANCES_URL}?${query}`)
    .then((d: AxiosResponse<GuidancesQueryResponse>) => d.data)
}

const getGuidanceById = (id: ID): Promise<Guidance | undefined> => {
  return axios
    .get(`${GET_GUIDANCE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Guidance>>) => response.data)
    .then((response: Response<Guidance>) => response.data)
}

const getGuidanceCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_GUIDANCE_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getGuidanceLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_GUIDANCE_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}


const createGuidance = (guidance: Guidance): Promise<Guidance | undefined> => {
  return axios
    .post(CREATE_GUIDANCE_URL, guidance)
    .then((response: AxiosResponse<Response<Guidance>>) => response.data)
    .then((response: Response<Guidance>) => response.data)
}

const updateGuidance = (guidance: Guidance): Promise<Guidance | undefined> => {
  return axios
    .put(`${UPDATE_GUIDANCE_URL}/${guidance.id}`, guidance)
    .then((response: AxiosResponse<Response<Guidance>>) => response.data)
    .then((response: Response<Guidance>) => response.data)
}

const deleteGuidance = (guidanceId: ID): Promise<void> => {
  return axios.delete(`${DELETE_GUIDANCE_URL}/${guidanceId}`).then(() => {})
}

const deleteSelectedGuidances = (guidanceIds: Array<ID>): Promise<void> => {
  const requests = guidanceIds.map((id) => axios.delete(`${DELETE_GUIDANCE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getGuidances, getCustomerGuidances, deleteGuidance, getGuidanceCountChart, getGuidanceLessonCountChart, deleteSelectedGuidances, getGuidanceById, createGuidance, updateGuidance}
