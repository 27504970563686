import {ID, Response} from '../../../../../_metronic/helpers'
export type MessageType = {
    id?: ID
    
    name?: string
}

export type MessageTypesQueryResponse = Response<Array<MessageType>>

export const initialMessageType: MessageType = {
    
    name: ''
}

export const initialMessageTypes: MessageType[] = [{
    
    name: ''
}]
