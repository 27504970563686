import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {AcademicYear, AcademicYearsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ACADEMICYEAR_URL = `${API_URL}/academicyear/getacademicyear`
const CREATE_ACADEMICYEAR_URL = `${API_URL}/academicyear/addacademicyear`
const UPDATE_ACADEMICYEAR_URL = `${API_URL}/academicyear/updateacademicyear`
const DELETE_ACADEMICYEAR_URL = `${API_URL}/academicyear/deleteacademicyear`
const GET_ACADEMICYEARS_URL = `${API_URL}/academicyear/getacademicyears`

const getAcademicYears = (query: string): Promise<AcademicYearsQueryResponse> => {
  return axios
    .get(`${GET_ACADEMICYEARS_URL}?${query}`)
    .then((d: AxiosResponse<AcademicYearsQueryResponse>) => d.data)
}

const getAcademicYearById = (id: ID): Promise<AcademicYear | undefined> => {
  return axios
    .get(`${GET_ACADEMICYEAR_URL}/${id}`)
    .then((response: AxiosResponse<Response<AcademicYear>>) => response.data)
    .then((response: Response<AcademicYear>) => response.data)
}

const createAcademicYear = (academicyear: AcademicYear): Promise<AcademicYear | undefined> => {
  return axios
    .post(CREATE_ACADEMICYEAR_URL, academicyear)
    .then((response: AxiosResponse<Response<AcademicYear>>) => response.data)
    .then((response: Response<AcademicYear>) => response.data)
}

const updateAcademicYear = (academicyear: AcademicYear): Promise<AcademicYear | undefined> => {
  return axios
    .put(`${UPDATE_ACADEMICYEAR_URL}/${academicyear.id}`, academicyear)
    .then((response: AxiosResponse<Response<AcademicYear>>) => response.data)
    .then((response: Response<AcademicYear>) => response.data)
}

const deleteAcademicYear = (academicyearId: ID): Promise<void> => {
  return axios.delete(`${DELETE_ACADEMICYEAR_URL}/${academicyearId}`).then(() => {})
}

const deleteSelectedAcademicYears = (academicyearIds: Array<ID>): Promise<void> => {
  const requests = academicyearIds.map((id) => axios.delete(`${DELETE_ACADEMICYEAR_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getAcademicYears, deleteAcademicYear, deleteSelectedAcademicYears, getAcademicYearById, createAcademicYear, updateAcademicYear}
