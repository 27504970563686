import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ChartModel} from '../../../../../_metronic/partials/widgets/charts/_models'
import {Customer, CustomerTransfer, CustomerImage, CustomersQueryResponse, CustomerImport} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_CUSTOMER_URL = `${API_URL}/customer/getcustomer`
const GET_CUSTOMER_COUNT_URL = `${API_URL}/customer/getcustomercount`
const GET_CUSTOMER_INFO_URL = `${API_URL}/customer/getcustomerinfo`
const GET_CUSTOMER_COUNT_CHART_URL = `${API_URL}/customer/getcustomercountchart`
const GET_CUSTOMER_LESSON_COUNT_CHART_URL = `${API_URL}/customer/getcustomerlessoncountchart`
const CREATE_CUSTOMER_URL = `${API_URL}/customer/addcustomer`
const UPDATE_CUSTOMER_URL = `${API_URL}/customer/updatecustomer`
const TRANSFER_CUSTOMER_URL = `${API_URL}/customer/transfercustomer`
const IMPORT_CUSTOMER_URL = `${API_URL}/customer/importcustomer`
const DELETE_CUSTOMER_URL = `${API_URL}/customer/deletecustomer`
const GET_CUSTOMERS_URL = `${API_URL}/customer/getcustomers`

const getCustomers = (query: string): Promise<CustomersQueryResponse> => {
  return axios
    .get(`${GET_CUSTOMERS_URL}?${query}`)
    .then((d: AxiosResponse<CustomersQueryResponse>) => d.data)
}

const getCustomerById = (id: ID): Promise<Customer | undefined> => {
  return axios
    .get(`${GET_CUSTOMER_URL}/${id}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const getCustomerCount = (institutionId: ID): Promise<number | undefined> => {
  return axios
    .get(`${GET_CUSTOMER_COUNT_URL}/${institutionId}`)
    .then((response: AxiosResponse<Response<number>>) => response.data)
    .then((response: Response<number>) => response.data)
}

const getCustomerInfo = (id: ID): Promise<Customer | undefined> => {
  return axios
    .get(`${GET_CUSTOMER_INFO_URL}/${id}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const getCustomerCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CUSTOMER_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const getCustomerLessonCountChart = (query: string): Promise<ChartModel | undefined> => {
  return axios
    .get(`${GET_CUSTOMER_LESSON_COUNT_CHART_URL}?${query}`)
    .then((response: AxiosResponse<Response<ChartModel>>) => response.data)
    .then((response: Response<ChartModel>) => response.data)
}

const createCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return axios
    .post(CREATE_CUSTOMER_URL, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const updateCustomer = (customer: Customer): Promise<Customer | undefined> => {
  return axios
    .put(`${UPDATE_CUSTOMER_URL}/${customer.id}`, customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const transferCustomer = (customerTransfer: CustomerTransfer): Promise<boolean | undefined> => {
  return axios
    .post(TRANSFER_CUSTOMER_URL, customerTransfer)
    .then((response: AxiosResponse<Response<boolean>>) => response.data)
    .then((response: Response<boolean>) => response.data)
}

const importCustomer = (customerImport: CustomerImport): Promise<boolean | undefined> => {
  const formData = new FormData();
  if (customerImport.institutionId !== undefined)
      formData.append('institutionId', String(customerImport.institutionId));
  if (customerImport.subinstitutionId !== undefined)
      formData.append('subinstitutionId', String(customerImport.subinstitutionId));
  if (customerImport.academicYearId !== undefined)
      formData.append('academicYearId', String(customerImport.academicYearId));
  if (customerImport.file !== undefined)
      formData.append('file', customerImport.file);
  return axios
      .post(IMPORT_CUSTOMER_URL, formData)
      .then((response: AxiosResponse<Response<boolean>>) => response.data)
      .then((response: Response<boolean>) => response.data)
}

const deleteCustomer = (customerId: ID): Promise<void> => {
  return axios.delete(`${DELETE_CUSTOMER_URL}/${customerId}`).then(() => {})
}

const deleteSelectedCustomers = (customerIds: Array<ID>): Promise<void> => {
  const requests = customerIds.map((id) => axios.delete(`${DELETE_CUSTOMER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCustomers, getCustomerCount, getCustomerInfo,deleteCustomer, getCustomerLessonCountChart, getCustomerCountChart, deleteSelectedCustomers, getCustomerById, createCustomer, updateCustomer, transferCustomer, importCustomer}
