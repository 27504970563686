/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {RoleHelper} from '../../../../app/modules/auth/components/RoleHelper'
import {useRole, setRole} from './RolesManager'

const Roles: FC = () => {
  const { currentUser, auth, saveAuth } = useAuth()
  const intl = useIntl()

  // TODO : this function 
  const setCurrentRole = (name: string) => {
    setRole(name)
    if(auth !== undefined)
    {
      auth.currentRole = name
      saveAuth(auth)
    }
    window.location.reload()
  }

  return (
    <div
      className='menu-item px-2'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-2'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'klh'})}
          <span className='fs-8 rounded bg-light px-1 py-2 position-absolute translate-middle-y top-50 end-0'>
            {RoleHelper(auth?.currentRole) + (' ')}
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-155px py-4'>
        {currentUser?.roles?.map((l) => (
          <div
            className='menu-item px-1'
            key={l}
            onClick={() => {
              setCurrentRole(l)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-2', {active: l === auth?.currentRole})}
            >
                {RoleHelper(l)}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Roles}
