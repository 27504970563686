import clsx from 'clsx'
import {FC, useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderUserMenu,
  QuickLinks,
  ThemeModeSwitcher,
} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {RoleHelper} from '../../../../app/modules/auth/components/RoleHelper'
import {getFileContent} from '../../../../app/modules/file/files-list/core/_requests'
import {Chat} from '../../../../app/modules/chat/chats-list/core/_models'
import {ClaimModel} from '../../../../app/modules/auth/core/_models'
import {useYearName} from '../../../../_metronic/partials/layout/header-menus/YearsManager'
import {useTermName} from '../../../../_metronic/partials/layout/header-menus/TermsManager'
import {RoleModel} from '../../../../app/modules/auth/core/_models'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()
  const {currentUser, auth} = useAuth()
  const [image, setImage] = useState(toAbsoluteUrl('/media/avatars/blank.png'));
  const [chat, setChat] = useState<Chat | null>(null);

  const currentYearName = useYearName()
  const currentTermName = useTermName()

  const isYearAndTerm = auth?.currentRole!==RoleModel.Admin &&
    auth?.currentRole!==RoleModel.Seller &&
    auth?.currentRole!==RoleModel.InstitutionManager &&
    auth?.currentRole!==RoleModel.InstitutionAdmin

  useEffect(() => {
    if(currentUser?.imageId !== undefined && currentUser?.imageId !== null)
      getFileContent(currentUser?.imageId).then((fileContent) => {     
        if(fileContent !== undefined)
        {  
          var logoUrlBlob = new Blob([fileContent],{type: fileContent.type}, )
          setImage(URL.createObjectURL(logoUrlBlob))
        }
      })
  }, [])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          
            {isYearAndTerm ? currentYearName + (' | ') + currentTermName + (' | ')   : <></>} 
            {RoleHelper(auth?.currentRole)}
          {/* end::Menu wrapper */}
        </div>
      {/* CHAT */}
      {(currentUser?.menus?.includes(ClaimModel.chat)) && 
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom position-relative',
              toolbarButtonHeightClass
            )}
            id='kt_drawer_chat_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com012.svg'
              className={toolbarButtonIconSizeClass}
            />
            {(chat !== undefined ? 
            <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
            : <></>)}
          </div>
          {/* end::Menu wrapper */}
        </div>
      }

      {/* Quick links */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen025.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
        {/* end::Menu wrapper */}
      </div>
      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
        />
      </div>
      {/* end::Theme mode */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={image} alt='Oprazi' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/*config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )*/}
    </div>
  )
}

export {Topbar}
